import { Container } from "../style";
import styled from "styled-components";

import Logo from "../images/logo.svg";
import BG from "../images/header-bg.png";

import Phone from "../images/Group 2.svg";
import Address from "../images/Group 3.svg";
import Mail from "../images/Group 4.svg";

import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const HeaderStyled = styled.div`
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-size: cover;

  /* height: 450px; */
  color: #fff;
  display: flex;

  > .main {
    padding: 25px 0 0 0;
    background-color: rgba(255, 255, 255, 0.4);
    width: 80%;
    height: 100%;
    > div {
      > nav {
        display: flex;
        column-gap: 20px;
        > a {
          color: #f6f6f6;
          font-size: 20px;
          text-decoration: none;
          /* text-shadow: 0px 4px 10px #000000; */
          transition-duration: 0.4s;
          cursor: pointer;
          &:hover {
            text-shadow: 0px 4px 10px #000000;
          }
        }
      }

      > img {
        width: 250px;
        margin: 80px 0 0 -10px;
      }

      > h1 {
        font-size: 64px;
      }

      > p {
        margin-top: 20px;
        font-size: 24px;
        width: 500px;
        border-left: 1px solid #3341a5;
        padding: 0 0 0 10px;
      }

      > a {
        text-decoration: none;
        color: #ffffff;
        font-size: 24px;
        background-color: #3341a5;
        padding: 10px;
        display: block;
        width: 180px;
        text-align: center;
        margin-top: 20px;
        transition-duration: 0.4s;
        cursor: pointer;
        margin-bottom: 250px;

        &:hover {
          background-color: #000;
        }
      }
    }
  }
  > .secondary {
    padding: 25px 20px 0 0;
    width: 30%;
    font-size: 13px;

    > div {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 10px;
      > img {
        width: 35px;
      }
    }
  }
`;

function Header() {
  return (
    <BrowserRouter>
      <HeaderStyled>
       
        <div className="main">
          <Container>
            <nav>
              <Link>Главная</Link>
              <Link>О нас</Link>
              <Link>Наши проекты</Link>
              <Link>Контакты</Link>
            </nav>
            <img src={Logo} />
            <h1 className="header-title">
              Ваше доверие <br />
              Наша работа
            </h1>
            <p className="header-text">
              В настоящее время мы являемся одной из успешных компаний по
              строительству сложных объектов
            </p>

            <Link href='#contact'>Подробнее</Link>
          </Container>
        </div>
        <div className="secondary">
          <div>
            <div>
              <span>+992 937 67 21 21</span>
              <br />
              <span>+992 88 300 40 40</span>
            </div>
            <img src={Phone} />
          </div>

          <div>
            <div>
              <span>г. Душанбе,</span>
              <br />
              <span>ул. Ф. Ниёзӣ 19, кв 14</span>
            </div>
            <img src={Address} />
          </div>

          <div>
            <span>Nurob-2004@mail.ru</span>
            <img src={Mail} />
          </div>
        </div>
      </HeaderStyled>
    </BrowserRouter>
  );
}

export default Header;
