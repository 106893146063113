function Footer(){
    return (
        <div style={{fontSize:"20px", 
        backgroundColor:"#3341A5",
        textAlign:"center",
        paddingBottom:"35px",
        paddingTop:"35px",
        color:"white"}}>
          ООО “Нуроб” - 2024
        </div>
      );
}

export default Footer;

