import styled from "styled-components";
import { Container } from "../style";
import Sangtuda from "../images/sangtuda.png";
import Sangtuda2 from "../images/sangtuda2.png";
import Rogun from "../images/rogun.png";
import Hisor from "../images/hissar.png";
import Flow from "../images/flow.png";
import Ugl from "../images/ugl.png";
import School from "../images/school.jpg";
import { useTranslation } from "react-i18next";


import "../App.css";

const OurProjectsStyled = styled.div`
  padding: 70px 0;

  > div {
    > h1 {
      font-size: 48px;
      font-weight: 700;
    }

    > .block-1 {
      margin-top: 40px;
      display: flex;
      column-gap: 30px;
      /* flex-wrap: wrap; */
      > div {
        > h1 {
          font-size: 64px;
          font-weight: 800;
          color: #777;
          margin-top: 35px;
        }
        > h2 {
          font-size: 32px;
          margin-top: 10px;
        }

        > p {
          font-size: 15px;
          margin-top: 10px;
          width: 400px;
          color: #333;
        }

        > a {
          text-decoration: none;
          background-color: #3341a5;
          color: #fff;
          font-size: 24px;
          padding: 10px;
          display: block;
          width: 150px;
          margin-top: 20px;
          transition-duration: 0.4s;
          cursor: pointer;

          &:hover {
            background-color: #000;
          }
        }
      }
      > img {
        height: 400px;
      }
    }
  }
`;

function OurProjects() {
  const { t, i18n } = useTranslation();

  return (
    <OurProjectsStyled id="projects">
      <Container>
        <h1 align="center">{t("our-projects")}</h1>

        <div className="block-1 blockRes">
          <div>
            <h1>01</h1>
            <h2>{t("ptitle-1")}</h2>
            <p>
            {t("ptext-1")}
            </p>
          </div>
          <img src={Sangtuda} />
        </div>
        <div className="block-1 block-2">
          <img src={Sangtuda2} />
          <div>
            <h1>02</h1>
            <h2>{t("ptitle-1")}</h2>
            <p>
            {t("ptext-2")}
              <br />
              <br />
              {t("ptext-21")}
              <br />
              <br />{t("ptext-22")}
            </p>
          </div>
        </div>
        <div className="block-1  block-mpobile">
            
          <div>
            <h1>02</h1>
            <h2>{t("ptitle-1")}</h2>
            <p>
            {t("ptext-2")}
              <br />
              <br />
              {t("ptext-21")}
              <br />
              <br />{t("ptext-22")}
            </p>
          </div>
          <img src={Sangtuda2} />
        </div>

        <div className="block-1">
          <div>
            <h1>03</h1>
            <h2>{t("ptitle-3")}</h2>
            <p>
            {t("ptext-3")}
            </p>
          </div>
          <img src={Rogun} />
        </div>
        <div className="block-1 block-2">
          <img src={Hisor} />
          <div>
            <h1>04</h1>
            <h2>{t("ptitle-4")}</h2>
            <p>
            {t("ptext-4")}
            </p>
          </div>
        </div>
        <div className="block-1 block-mpobile">
          <div>
            <h1>04</h1>
            <h2>{t("ptitle-4")}</h2>
            <p>
            {t("ptext-4")}
            </p>
          </div>
          <img src={Hisor} />
        </div>

        <div className="block-1">
          <div>
            <h1>05</h1>
            <h2>{t("ptitle-5")}</h2>
            <p>
            {t("ptext-5")}
            </p>
          </div>
          <img src={Flow} />
        </div>
        <div className="block-1 block-2">
          <img src={Ugl} />
          <div>
            <h1>06</h1>
            <h2>{t("ptitle-6")}</h2>
            <p>
            {t("ptext-6")}
              <br />
              <br />
              {t("ptext-61")}
            </p>
          </div>
        </div>
        <div className="block-1 block-mpobile">
          <div>
            <h1>06</h1>
            <h2>{t("ptitle-6")}</h2>
            <p>
            {t("ptext-6")}
              <br />
              <br />
              {t("ptext-61")}
            </p>
          </div>
          <img src={Ugl} />
        </div>

        <div className="block-1">
          <div>
            <h1>07</h1>
            <h2>{t("ptitle-7")}</h2>
            <p>
            {t("ptext-7")}
            </p>
          </div>
          <img src={School} />
        </div>

      </Container>
    </OurProjectsStyled>
  );
}

export default OurProjects;
