import { Container } from "../style";
import styled from "styled-components";
import AboutUsIMG from "../images/aboutUS.png"
import Up from "../images/up.svg"
import Down from "../images/down.svg"
import { useTranslation } from "react-i18next";


const AboutUsStyled = styled.div`
padding: 70px 0;
  background-color: #f6f6f6;


  >div{
    >div{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      >div{

        >div{
          >h1{
                font-size: 48px;
            }
            display: flex;
           
            >img{
              padding-left: 30px;
              width: 300px;
                          }
        }


        >.second{

          display: flex;

          >p{
          font-size: 20px;
          width: 500px;
          padding: 20px 0 0 20px;
          color: #333;
        }

        >img{
          height: 300px;
          width: 10px;
          padding: 10px 0 0 0 ;
        }
        }

      }
      >img{
        width: 300px;
        height: 500px;
        -webkit-box-shadow: 11px 14px 0px -1px #3341A5; 
        box-shadow: 25px 25px 0px -1px #3341A5;

      }
    }
  }

`

function AboutUs() {
  const { t, i18n } = useTranslation();

  return (
    <AboutUsStyled id="about-us" >
      <Container>
        <div className="aboutus">
          <div>
            <div>
              <h1>
                {t("aboutus-title")}
              </h1>
              <img src={Up} className="line" />

            </div>

            <div className="second">
              <img src={Down} />
              <p>
              {t("aboutus-text1")}
                <br />
                <br />
                {t("aboutus-text2")}
                <br />
                <br />
                {t("aboutus-text3")}

                <br />
                <br />
                {t("aboutus-text4")}
                <br />
                <br />
                {t("aboutus-text5")}
              </p>
            </div>

          </div>
          <img className="aboutus-img" src={AboutUsIMG} />
          
        </div>
      </Container>
    </AboutUsStyled>
  );
}

export default AboutUs;

