import AboutUs from "./components/AboutUs";
import Comment from "./components/Comment";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import OurProjects from "./components/OurProjects";
import Statistics from "./components/Statistics";
import React, { useState } from "react";

import { Container } from "./style";
import styled from "styled-components";

import Logo from "./images/logo.svg";

import Phone from "./images/Group 2.svg";
import Address from "./images/Group 3.svg";
import Mail from "./images/Group 4.svg";

import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import Vid1eo from "./vid.mp4";
import { useTranslation } from "react-i18next";

const HeaderStyled = styled.div`
  /* height: 450px; */
  color: #fff;
  display: flex;

  > .main {
    padding: 25px 0 0 0;
    background-color: rgba(255, 255, 255, 0.4);
    width: 80%;
    height: 100%;
    > div {
      > nav {
        align-items: center;
        display: flex;
        column-gap: 20px;
        > a {
          color: #f6f6f6;
          font-size: 20px;
          text-decoration: none;
          /* text-shadow: 0px 4px 10px #000000; */
          transition-duration: 0.4s;
          cursor: pointer;
          &:hover {
            text-shadow: 0px 4px 10px #000000;
          }
        }
        >button{
          background:#fff ;
         border: none;
         padding: 10px;
         border-radius: 10px;
         transition-duration: 0.4s;
         &:hover {
            background-color: #000;
            color: #fff;
          }
        }
      }

      > img {
        width: 250px;
        margin: 80px 0 0 -10px;
      }

      > h1 {
        font-size: 64px;
      }

      > p {
        margin-top: 20px;
        font-size: 24px;
        width: 500px;
        border-left: 1px solid #3341a5;
        padding: 0 0 0 10px;
      }

      > a {
        text-decoration: none;
        color: #ffffff;
        font-size: 24px;
        background-color: #3341a5;
        padding: 10px;
        display: block;
        width: 180px;
        text-align: center;
        margin-top: 20px;
        transition-duration: 0.4s;
        cursor: pointer;
        margin-bottom: 250px;

        &:hover {
          background-color: #000;
        }
      }
    }
  }
  > .secondary {
    padding: 25px 20px 0 0;
    width: 30%;
    font-size: 13px;

    > div {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 10px;
      > img {
        width: 35px;
      }
    }
  }
`;

function App() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('en'); // По умолчанию установим английский язык


  

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };


  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <BrowserRouter>
        <div className="video-background">
          <video autoPlay loop muted>
            <source src={Vid1eo} type="video/mp4" />
            {/* Add additional <source> tags for other formats if needed */}
            Your browser does not support the video tag.
          </video>
          {/* Content on top of the video */}
          <div className="content">
            <HeaderStyled>
              <div className="main">
                <Container>
                  <nav>
                    <Link to="#head" smooth>
                      <div>{t("main")}</div>
                    </Link>
                    <Link to="#about-us" smooth>
                      {t("about-us")}
                    </Link>
                    <Link to="#projects" smooth>
                      {t("projects")}
                    </Link>
                    <Link to="#contacts" smooth>
                      {t("contacts")}
                    </Link>
                    {language === 'ru' ? (
        <button onClick={() => changeLanguage('en')}>EN</button>
      ) : (
        <button onClick={() => changeLanguage('ru')}>RU</button>
      )}
                  </nav>
                  <div className="burger-menu">
                    <button
                      className="burger-menu-button"
                      onClick={handleMenuToggle}
                    >
                      Menu
                    </button>
                    {isOpen && (
                      <div className="menu-items">
                        <Link
                          onClick={handleMenuToggle}
                          className="menu-item"
                          to="#head"
                          smooth
                        >
                          <div>{t("main")}</div>
                        </Link>
                        <Link
                          onClick={handleMenuToggle}
                          className="menu-item"
                          to="#about-us"
                          smooth
                        >
                          {t("about-us")}
                        </Link>
                        <Link
                          onClick={handleMenuToggle}
                          className="menu-item"
                          to="#projects"
                          smooth
                        >
                          {t("projects")}
                        </Link>
                        <Link
                          onClick={handleMenuToggle}
                          className="menu-item"
                          to="#contacts"
                          smooth
                        >
                          {t("contacts")}
                        </Link>
                        {language === 'ru' ? (
        <button className="lang-btn" onClick={() => changeLanguage('en')}>EN</button>
      ) : (
        <button className="lang-btn" onClick={() => changeLanguage('ru')}>RU</button>
      )}
                      </div>
                    )}
                  </div>

                  <img src={Logo} />
                  <h1 className="header-title">
                    {t("header-title")}
                    <br />
                    {t("header-title2")}
                  </h1>
                  <p className="header-text">{t("header-text")}</p>

                  <Link to="#about-us" smooth>
                    {t("more")}
                  </Link>
                </Container>
              </div>
              <div className="secondary">
                <div>
                  <div>
                    <span>+992 937 67 21 21</span>
                    <br />
                    <span>+992 88 300 40 40</span>
                  </div>
                  <img src={Phone} />
                </div>

                <div>
                  <div>
                    <span>{t("city")}</span>
                    <br />
                    <span>{t("street")}</span>
                  </div>
                  <img src={Address} />
                </div>

                <div>
                  <span>Nurob-2004@mail.ru</span>
                  <img src={Mail} />
                </div>
              </div>
            </HeaderStyled>
          </div>
        </div>
      </BrowserRouter>

      <AboutUs />
      <Statistics />
      <OurProjects />
      <Comment />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
