import { Container } from "../style";
import styled from "styled-components";
import { useTranslation } from "react-i18next";


const StatisticsStyled = styled.div`
background-color: #CFD2E6;
padding: 60px 0 ;
>div{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  >div{
    >h1{
      color:#8189C5;
      font-size: 96px;
      font-weight: bold;
    }
    >p{
      color: #333;
      font-size: 25px;
      width: 140px;
      margin-top: 10px;
    }
  }
}
`

function Statistics(){

  const { t, i18n } = useTranslation();


    return (
        <StatisticsStyled>
          <Container>
            <div>
              <h1>
              40
              </h1>
              <p className="st-text">
              {t("st-1")}
              </p>
            </div>
            <div>
              <h1>
              6
              </h1>
              <p className="st-text" >
              {t("st-2")}
              </p>
            </div>
            <div>
              <h1>
              20
              </h1>
              <p className="st-text">
              {t("st-3")}
              </p>
            </div>
            <div>
              <h1>
              7
              </h1>
              <p className="st-text">
              {t("st-4")}
              </p>
            </div>
          </Container>
        </StatisticsStyled>
      );
}

export default Statistics;

