import styled from "styled-components"

const Container = styled.div `

margin: 0 auto;
max-width: 1024px;
padding: 0 20px;

`

export {Container}