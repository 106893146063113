import styled from "styled-components";
import { Container } from "../style";

import Quote_1 from "../images/quote.svg";
import Quote_2 from "../images/quote-2.svg";
import Line from "../images/Line.svg";
import { useTranslation } from "react-i18next";

const CommentStyled = styled.div`
  padding: 70px 0;
  background-color: #f6f6f6;
  > div {
    > .quote-1 {
      width: 50px;
    }

    > div {
      padding-left: 20px;

      > h1 {
        width: 490px;
        font-size: 48px;

        margin-top: 20px;
      }
      > .line {
        width: 360px;
      }

      > p {
        font-size: 24px;
        margin-top: 10px;
        color: #333;
      }
    }

    > .author {
      justify-content: flex-end;
      display: flex;
      > div {
        text-align: end;
        > .line {
          width: 360px;
        }

        > p {
          font-size: 20px;
          margin-top: 5px;
        }
      }
    }

    > .quote-2 {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      > img {
        width: 50px;
      }
    }
  }
`;

function Comment() {
  const { t, i18n } = useTranslation();

  return (
    <CommentStyled>
      <Container>
        <img className="quote-1" src={Quote_1} />
        <div>
          <h1 className="comment-tile">{t("q-titlestate")}</h1>
          <img className="line" src={Line} />
          <p>
            {t("q-text")}
            <br />
            <br />
            {t("q-text2")}
            <br />
            <br />
            {t("q-text3")}
          </p>
        </div>

        <div className="author">
          <div>
            <img className="line" src={Line} />
            <p>{t("q-text4")}</p>
            <p>{t("q-text5")}</p>
          </div>
        </div>

        <div className="quote-2">
          <img src={Quote_2} />
        </div>
      </Container>
    </CommentStyled>
  );
}

export default Comment;
