import styled from "styled-components";
import { Container } from "../style";


import ContactsBG from "../images/contacts-bg.png"
import Phone from "../images/Group 2.svg"
import Address from "../images/Group 3.svg"
import Mail from "../images/Group 4.svg"

import { useTranslation } from "react-i18next";



const ContactStyled = styled.div`


>div{
  display: flex;
  justify-content: space-between;
  
  >.form{
    padding: 35px 0;

    width: 520px;
   >div{
    >h1{
      font-weight: 600;
      color: #777;
      font-size: 36px;
    }

    >input {
      width: 520px;
      border: none;
      font-size: 16px;
      border-bottom: 1px solid #888;
      padding-bottom: 8px;
      outline: none;
      margin: 10px 0;
    }

    >textarea {
      width: 520px;
      
      font-size: 16px;
      border: 1px solid #888;
      outline: none;
      height: 260px;
      background-color: #F4F4F4;
      padding: 10px;
    }

    >div{
    
      margin-top: 20px;
      >button {
        font-size: 20px;
        background-color: #3341A5;
        padding: 10px;
        outline: none;
        border: none;
        color: #fff;
        transition-duration: 0.4s;
       cursor: pointer;
                
                &:hover {
                    background-color: #000;
                }
      }
    }
   }
    
  }

  >.contacts{
    padding: 35px 20px;
    width: 380px;
    background-image: url(${ContactsBG});
    background-repeat: no-repeat;
    background-size: cover;

    font-size: 16px;
    color: #fff;
        
        >div{
            display: flex;
            
            align-items: center;
            column-gap: 10px;
            margin-bottom: 10px;
            >img{
                width: 40px;
            }
        }
    >h1{
      font-weight: 600;
      color: #fff;
      font-size: 36px;
      margin-bottom: 15px;
    }
  }
}

`

function Contact() {
  const { t, i18n } = useTranslation();

  return (
    <ContactStyled>
      <div className="contactus" id="contacts">
        <div className="form">
        <Container>
          <h1>{t("contatc-us")}</h1>
          <br />
          <input placeholder={t("name")} />
          <br />
          <input placeholder={t("phone-number")} />
          <br />
          <br />
          <textarea placeholder={t("text-request")} />
          <br />
          <div>
            <button>
            {t("send")}
            </button>
          </div>

          </Container>
        </div>
        <div className="contacts">
          <h1>{t("our-contacts")}</h1>
          <div>
            <img src={Phone} />
            <div>
              <span>
                +992 937 67 21 21
              </span>
              <br />
              <span>
                +992 88 300 40 40
              </span>
            </div>
          </div>

          <div>
            <img src={Address} />
            <div>
              <span>
                {t("city")}
              </span>
              <br />
              <span>
              {t("street")}
              </span>
            </div>

          </div>

          <div>
            <img src={Mail} />
            <span>
              Nurob-2004@mail.ru
            </span>

          </div>
        </div>
      </div>
    </ContactStyled>
  );
}

export default Contact;

